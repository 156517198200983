'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
// import { header } from './view/_header';
import { swiper } from './view/_swiper';
import { accordion } from './view/_accordion';
import { modal } from './view/_modal';
import { header } from './view/_header';
import { text } from './view/_text';
import { btn } from './view/_btn';
import { top } from './view/_top';
import { search } from './view/_search';
import { omit } from './view/_omit';
import { more } from './view/_more';
import { cookie } from './view/_cookie';
