document.addEventListener("DOMContentLoaded", function () {
	const areaItems = document.querySelectorAll(".c-search__areaItem");
	const tabLinks = document.querySelectorAll(".p-areaSearch__tabLink");
	const tabContents = document.querySelectorAll(".p-areaSearch__tabContent");
	const mapImages = document.querySelectorAll(".p-areaSearch__mapImgItem");

	// 各エリアアイテムの数をカウントして表示
	function updateAreaCounts() {
		const activeTabContent = document.querySelector(
			".p-areaSearch__tabContent.-active"
		);
		const searchItems = activeTabContent
			? activeTabContent.querySelectorAll(".c-search__item")
			: document.querySelectorAll(".c-search__item");

		areaItems.forEach((item) => {
			const area = item.getAttribute("data-area");
			const areaNum = item.querySelector(".c-search__areaNum");
			let count = 0;

			searchItems.forEach((searchItem) => {
				const itemArea = searchItem.getAttribute("data-area");
				if (area === "all" || itemArea === area) {
					count++;
				}
			});

			if (areaNum) {
				areaNum.textContent = count;
			}
		});
	}

	// 絞り込み処理
	function filterItems(area) {
		const activeTabContent = document.querySelector(
			".p-areaSearch__tabContent.-active"
		);
		const searchItems = activeTabContent
			? activeTabContent.querySelectorAll(".c-search__item")
			: document.querySelectorAll(".c-search__item");

		searchItems.forEach((searchItem) => {
			const itemArea = searchItem.getAttribute("data-area");
			if (area === "all" || itemArea === area) {
				searchItem.style.display = "";
				searchItem.classList.remove("-hidden");
			} else {
				searchItem.style.display = "none";
				searchItem.classList.add("-hidden");
			}
		});
	}

	// エリアに対応する画像を表示
	function showMapImage(area) {
		mapImages.forEach((img) => {
			const imgArea = img.getAttribute("data-area");
			if (imgArea === area) {
				img.style.display = "";
			} else {
				img.style.display = "none";
			}
		});
	}

	// エリアアイテムのクリックイベント
	function setupAreaItemClick() {
		areaItems.forEach((item) => {
			item.addEventListener("click", function (event) {
				event.preventDefault();

				areaItems.forEach((i) => i.classList.remove("-active"));
				this.classList.add("-active");

				const area = this.getAttribute("data-area");
				filterItems(area);
				showMapImage(area);
			});
		});
	}

	// タブリンクのクリックイベント
	function setupTabLinkClick() {
		tabLinks.forEach((link) => {
			link.addEventListener("click", function (event) {
				event.preventDefault();

				tabLinks.forEach((link) =>
					link.parentElement.classList.remove("-active")
				);
				this.parentElement.classList.add("-active");

				const targetTab = this.getAttribute("data-tab");
				tabContents.forEach((content) => content.classList.remove("-active"));
				document.getElementById(targetTab).classList.add("-active");

				// コンテンツをリセット
				areaItems.forEach((i) => i.classList.remove("-active"));
				const allAreaItems = document.querySelectorAll(
					'.c-search__areaItem[data-area="all"]'
				);
				allAreaItems.forEach((i) => i.classList.add("-active"));
				filterItems("all");
				showMapImage("all");
				updateAreaCounts();
			});
		});
	}

	// 初期化
	function init() {
		updateAreaCounts();
		setupAreaItemClick();
		setupTabLinkClick();
		showMapImage("all"); // 初期表示でallを表示
	}

	init();
});
