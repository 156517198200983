// p-topが存在する場合
if ($(".p-top").length) {
	$(window).on("load", function () {
		const $loadingScreen = $("#loading-screen");
		const $firstViewText = $(".js-firstview-text");
		//初期ロード時は.p-topKv__bg .js-slide-item > .slider-textをdisplay:blockにしておく
		$(".p-topKv__bg .js-slide-item > .slider-text").css("display", "block");

		$loadingScreen.on("animationend", function () {
			$loadingScreen.hide();
			$firstViewText.addClass("show");
		});
		setTimeout(function () {
			$firstViewText.addClass("show");
		}, 500);
	});

	// p-topService__itemにホバーしたときの処理
	const $serviceItems = $(".p-topService__item");

	$serviceItems.on("mouseenter", function () {
		const $item = $(this);
		$item.addClass("expanded");
		$item.find(".p-topService__itemMore").fadeOut(600);
		setTimeout(function () {
			const $itemText = $item.find(".p-topService__itemText");
			$itemText.addClass("show");
			const $img = $item.find("picture source");
			// 画像の切り替え
			$img.each(function () {
				const $this = $(this);
				const srcset = $this.attr("srcset");
				if (srcset.includes("pc")) {
					//itemがexpandedクラスを持っている場合は画像を切り替える
					if ($item.hasClass("expanded")) {
						$this.attr("srcset", srcset.replace("pc", "sp"));
					}
					// $this.attr("srcset", srcset.replace("pc", "sp"));
				}
			});
		}, 800);

		$serviceItems.not($item).addClass("shrink");
	});

	$serviceItems.on("mouseleave", function () {
		const $item = $(this);
		$item.removeClass("expanded");
		$item.find(".p-topService__itemMore").fadeIn(600);
		const $itemText = $item.find(".p-topService__itemText");
		$itemText.removeClass("show");
		$serviceItems.removeClass("shrink");

		// 画像を元に戻す
		setTimeout(function () {
			const $img = $item.find("picture source");
			$img.each(function () {
				const $this = $(this);
				const srcset = $this.attr("srcset");
				if (srcset.includes("sp")) {
					//itemがexpandedクラスを持っていない場合は画像を切り替える
					if (!$item.hasClass("expanded")) {
						$this.attr("srcset", srcset.replace("sp", "pc"));
					}
					// $this.attr("srcset", srcset.replace("sp", "pc"));
				}
			});
		}, 1000);
	});
}
