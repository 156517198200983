function updateRange() {
  let range = 4; // デフォルトのrange
	// console.log(range);
  if (window.innerWidth <= 480) {
    range = 2; // 横幅480px以下の場合のrange
  }
  document.cookie = "pagination_range=" + range + "; path=/";
	// console.log(range);
}

window.addEventListener('resize', updateRange);
window.addEventListener('load', updateRange);
