document.addEventListener('scroll', function() {
	const button = document.querySelector('.c-btn__following');
	if (button) {
		button.style.opacity = '0'; // Adjust the opacity value as needed
	}
});

document.addEventListener('scrollend', function() {
	const button = document.querySelector('.c-btn__following');
	if (button) {
		button.style.opacity = '1';
	}
});
