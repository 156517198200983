document.addEventListener("DOMContentLoaded", function () {
	const header = document.getElementById("js-header");
	const subContainer = document.querySelector(".l-header__subContainer");
	const serviceLink = document.querySelector(
		".l-navigation__item:nth-child(1) .l-navigation__link"
	);

	// 読み込時のヘッダーの-active状態を設定
	setActiveState(window.scrollY > 0);

	// ヘッダーの-active状態を設定する関数
	function setActiveState(isActive) {
		if ($(".p-top").length) {
			if (isActive) {
				header.classList.add("-active");
				// logoImg.src = activeLogoSrc;
			} else {
				header.classList.remove("-active");
				// logoImg.src = originalLogoSrc;
			}
		}
	}

	// スクロールイベント
	window.addEventListener("scroll", function () {
		setActiveState(window.scrollY > 0);
	});

	// ヘッダーにホバーしたときのイベント
	header.addEventListener("mouseenter", function () {
		setActiveState(true);
	});

	// ヘッダーからマウスリーブしたときのイベント
	header.addEventListener("mouseleave", function () {
		if (window.scrollY === 0 && !subContainer.matches(":hover")) {
			setActiveState(false);
		}
	});

	// サービスリンクにホバーしたときのイベント
	serviceLink.addEventListener("mouseenter", function () {
		subContainer.classList.add("-isShow");
	});

	// サービスリンクからマウスリーブしたときのイベント
	serviceLink.addEventListener("mouseleave", function () {
		if (!subContainer.matches(":hover")) {
			subContainer.classList.remove("-isShow");
		}
	});

	// サブコンテナからマウスリーブしたときのイベント
	subContainer.addEventListener("mouseleave", function () {
		subContainer.classList.remove("-isShow");
		if (window.scrollY === 0) {
			setActiveState(false);
		}
	});

	// ハンバーガーメニュー
	const hamburger = document.getElementById("js-hamburger");
	const navigationSp = document.querySelector(".l-navigation__sp");
	const hamburgerText = document.querySelector(".l-header__hamburgerText");
	const logo = document.querySelector(".l-header__logo");
	const logoImg = document.querySelector(".l-header__logoImg");
	const originalLogoSrc = "/assets/img/common/img-logo-white.svg";
	const activeLogoSrc = "/assets/img/common/img-logo.svg";
	hamburger.addEventListener("click", function () {
		navigationSp.classList.toggle("-isShow");
		hamburger.classList.toggle("is-open");

		hamburgerText.textContent =
			hamburgerText.textContent === "Menu" ? "Close" : "Menu";
		//logoImgを切り替える
		if (hamburger.classList.contains("is-open")) {
			logoImg.src = originalLogoSrc;
		} else {
			logoImg.src = activeLogoSrc;
		}
		logo.classList.toggle("is-white");
	});

	// モーダル
	const modalOpen = $(".js-modalOpen");
	const modalClose = $("#js-modalClose");
	const modalBg = $("#js-modalBg");
	const modal = $(".l-header__modal");

	modalOpen.on("click", function () {
		modal.fadeIn();
	});

	modalClose.on("click", function () {
		modal.fadeOut();
	});

	modalBg.on("click", function () {
		modal.fadeOut();
	});

	// l-innerNavの制御
	const nav = document.querySelector(".l-innerNav");
	const navHead = document.querySelector(".l-innerNav__spHead");

	if (nav && navHead) {
		function handleResize() {
			const isSP = window.matchMedia("(max-width: 767px)").matches;
			if (isSP) {
				// スクロールイベント
				window.addEventListener("scroll", handleScroll);
				// クリックイベント
				navHead.addEventListener("click", toggleActiveClass);
			} else {
				// イベントリスナーを削除
				window.removeEventListener("scroll", handleScroll);
				navHead.removeEventListener("click", toggleActiveClass);
			}
		}

		function handleScroll() {
			if (window.scrollY <= 300) {
				nav.classList.remove("-hide");
				nav.classList.add("-top");
			} else {
				nav.classList.add("-hide");
				nav.classList.remove("-top");
			}
		}

		function toggleActiveClass() {
			nav.classList.toggle("-hide");
		}

		// 初期化
		handleResize();

		window.addEventListener("resize", handleResize);
	}
});
