document.addEventListener('DOMContentLoaded', function() {
	var moreButton = document.getElementById('js-keyword-more');
	var keywordList = document.querySelector('.p-column__sidebarKeyword__list');

	if (moreButton && keywordList) {
			moreButton.addEventListener('click', function() {
					keywordList.classList.toggle('expanded');
					moreButton.classList.toggle('expanded');
					if (keywordList.classList.contains('expanded')) {
							moreButton.innerHTML = '<span class="btnText">閉じる<span class="btnIcon"></span></span>';
					} else {
							moreButton.innerHTML = '<span class="btnText">もっと見る<span class="btnIcon"></span></span>';
					}
			});
	}
});

var columnMoreButton = document.getElementById('js-column-more');
if (columnMoreButton) {
	columnMoreButton.addEventListener('click', function() {
			var list = document.querySelector('.p-column__wrap');
			if (list) {
					var listHeight = list.scrollHeight;
					list.style.maxHeight = (listHeight + 5) + 'px';
					list.classList.add('show');
					this.style.display = 'none';
			}
	});
}
