document.addEventListener('DOMContentLoaded', function() {
  const elements = document.querySelectorAll('.js-text-up');

  const onScroll = () => {
    elements.forEach(element => {
      const rect = element.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        element.classList.add('is-visible');
      }
    });
  };

  window.addEventListener('scroll', onScroll);
  onScroll(); // 初期チェック
});
