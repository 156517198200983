//swiper-containerがある場合のみ実行
if (document.querySelector(".sliderBg")) {
	var _playTime = 5000;
	const swiperBg = new Swiper(".sliderBg", {
		direction: "horizontal",
		loop: true,
		speed: 500,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			type: "bullets",
		},
		// navigation: {
		// 	nextEl: ".swiper-button-next",
		// 	prevEl: ".swiper-button-prev",
		// },
		autoplay: {
			delay: _playTime,
			disableOnInteraction: false,
		},
		// autoplay: false,
		// spaceBetween: 15,
		scrollbar: {
			el: ".swiper-scrollbar",
		},
		effect: "fade", //追加 フェード機能をONにする
		// slidesPerView: 1.2, //左右のスライドチラ見せ
		// centeredSlides: true, //左右のスライドチラ見せ
		breakpoints: {
			768: {
				//768px以上なら次を適用
				spaceBetween: 90,
				slidesPerView: 1.25,
			},
		},
	});
	swiperBg.autoplay.stop();
	//一秒後にスライドを再生
	setTimeout(function () {
		swiperBg.autoplay.start();
	}, 1000);

	const slideItems = document.querySelectorAll(
		".js-slide-item[data-swiper-slide-index]"
	);
	//スワイパーに連動してテキストも変更
	swiperBg.on("slideChange", function () {
		// console.log(swiper.realIndex);
		let index = swiperBg.realIndex;
		// console.log(index);
		slideItems.forEach((item) => {
			// console.log(item);
			//
			item.classList.remove("is-active");
		});
		slideItems[index].classList.add("is-active");
	});
}

if (document.querySelector(".sliderBanner")) {
	var _playTime = 3000;
	const sliderBanner = new Swiper(".sliderBanner", {
		direction: "horizontal",
		loop: true,
		speed: 500,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			type: "bullets",
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		spaceBetween: 47.5,
		scrollbar: {
			el: ".swiper-scrollbar",
		},

		slidesPerView: 1, //左右のスライドチラ見せ
		// centeredSlides: true, //左右のスライドチラ見せ
		breakpoints: {
			768: {
				//768px以上なら次を適用
				spaceBetween: 30,
				slidesPerView: 3,
				centeredSlides: false,
			},
		},
	});
}

if (document.querySelector(".sliderserviceKv")) {
	let _playTime = 0;
	const sliderBanner = new Swiper(".sliderserviceKv", {
		direction: "horizontal",
		loop: true,
		autoplay: {
			delay: _playTime,
			disableOnInteraction: false,
		},
		// autoplay: false,
		spaceBetween: 47.5,
		scrollbar: {
			el: ".swiper-scrollbar",
		},

		slidesPerView: 1, //左右のスライドチラ見せ
		spaceBetween: 0,
		speed: 14000,
		breakpoints: {
			768: {
				//768px以上なら次を適用
				spaceBetween: 16,
				loopedSlides: 2,
				slidesPerView: "auto",
			},
		},
	});
	let getTranslate;
	let speed = 14000;
	document.querySelectorAll(".sliderserviceKv").forEach(function (e) {
		e.addEventListener("mouseover", function () {
			getTranslate = sliderBanner.getTranslate();
			sliderBanner.setTranslate(getTranslate);
			sliderBanner.setTransition(0);
		});
		e.addEventListener("mouseout", function () {
			getTranslate = sliderBanner.getTranslate();
			let getSlideWidthMgLeft = document.querySelector(".swiper-slide-active")
				.style.marginLeft;
			if (getSlideWidthMgLeft) {
				getSlideWidthMgLeft = parseFloat(getSlideWidthMgLeft);
			} else {
				getSlideWidthMgLeft = 0;
			}
			let getSlideWidthMgRight = document.querySelector(".swiper-slide-active")
				.style.marginRight;
			if (getSlideWidthMgRight) {
				getSlideWidthMgRight = parseFloat(getSlideWidthMgRight);
			} else {
				getSlideWidthMgRight = 0;
			}
			let getSlideWidth = document.querySelector(
				".swiper-slide-active"
			).offsetWidth;
			let getTotalSlideWidth =
				getSlideWidthMgLeft + getSlideWidthMgRight + getSlideWidth;
			let diff = -getTotalSlideWidth - (getTranslate % getTotalSlideWidth);
			let diffTime = diff / -getSlideWidth;
			sliderBanner.setTranslate(getTranslate + diff);
			sliderBanner.setTransition(speed * diffTime);
		});
	});
}

if (document.querySelector(".pageTopKv")) {
	let _playTime = 0;
	const sliderPageTopKv= new Swiper(".pageTopKv", {
		direction: "horizontal",
		loop: true,
		autoplay: {
			delay: _playTime,
			disableOnInteraction: false,
		},
		// autoplay: false,
		scrollbar: {
			el: ".swiper-scrollbar",
		},

		slidesPerView: 1, //左右のスライドチラ見せ

		spaceBetween: 9,
		speed: 14000,
		breakpoints: {
			768: {
				//768px以上なら次を適用
				spaceBetween: 16,
				loopedSlides: 2,
				slidesPerView: "auto",
			},
		},
	});
	let getTranslate;
	let speed = 14000;
	document.querySelectorAll(".pageTopKv").forEach(function (e) {
		e.addEventListener("mouseover", function () {
			getTranslate = sliderPageTopKv.getTranslate();
			sliderPageTopKv.setTranslate(getTranslate);
			sliderPageTopKv.setTransition(0);
		});
		e.addEventListener("mouseout", function () {
			getTranslate = sliderPageTopKv.getTranslate();
			let getSlideWidthMgLeft = document.querySelector(".swiper-slide-active")
				.style.marginLeft;
			if (getSlideWidthMgLeft) {
				getSlideWidthMgLeft = parseFloat(getSlideWidthMgLeft);
			} else {
				getSlideWidthMgLeft = 0;
			}
			let getSlideWidthMgRight = document.querySelector(".swiper-slide-active")
				.style.marginRight;
			if (getSlideWidthMgRight) {
				getSlideWidthMgRight = parseFloat(getSlideWidthMgRight);
			} else {
				getSlideWidthMgRight = 0;
			}
			let getSlideWidth = document.querySelector(
				".swiper-slide-active"
			).offsetWidth;
			let getTotalSlideWidth =
				getSlideWidthMgLeft + getSlideWidthMgRight + getSlideWidth;
			let diff = -getTotalSlideWidth - (getTranslate % getTotalSlideWidth);
			let diffTime = diff / -getSlideWidth;
			sliderPageTopKv.setTranslate(getTranslate + diff);
			sliderPageTopKv.setTransition(speed * diffTime);
		});
	});
}

// コラム

if (document.querySelector(".sliderColumn")) {
	const swiperColumn = new Swiper(".sliderColumn", {
		direction: "horizontal",
		loop: true,
		navigation: {
			nextEl: ".swiper-button-next-column",
			prevEl: ".swiper-button-prev-column",
		},
		spaceBetween: 0,
		slidesPerView: 1,
		pagination: {
			el: ".swiper-pagination-column", // ページネーションの要素を指定
			clickable: true, // ドットをクリック可能にする
		},
	});
}

